import { useStaticQuery, graphql } from 'gatsby';

const usePartnersPage = () => {
    const { sanityPagePartners: partnersPage } = useStaticQuery(graphql`
        query {
            sanityPagePartners {
                bannerImage {
                    image {
                        ...ImageWithPreview
                    }
                    alt
                }
                bannerTitle {
                    en
                    se
                }
                bannerDescription {
                    se {
                        _rawContent
                    }
                    en {
                        _rawContent
                    }
                }
                ingress {
                    se {
                        _rawContent
                    }
                    en {
                        _rawContent
                    }
                }
                partnershipPrograms {
                    _key
                    programName
                    language {
                        translationKey
                    }
                    programDescription {
                        _rawContent
                        content {
                            _rawChildren
                        }
                    }
                    programKeyPoints
                    programBrochure {
                        asset {
                            url
                        }
                    }
                }
                formGdprMessage {
                    _rawEn
                    _rawSe
                }
                formSuccessMessage {
                    _rawEn
                    _rawSe
                }
                partnershipCases {
                    _id
                    title
                    url
                    logoWhite {
                        ...ImageWithPreview
                    }
                    keyPoints {
                        _key
                        en
                        se
                    }
                    fullDescription {
                        en {
                            _rawContent
                        }
                        se {
                            _rawContent
                        }
                    }
                }
                linkBoxTitle {
                    en
                    se
                }
                linkBoxDescription {
                    se {
                        _rawContent
                    }
                    en {
                        _rawContent
                    }
                }
                linkBoxLink {
                    slug {
                        current
                    }
                }
                linkBoxCta {
                    se
                    en
                }
                hammarbyStaff {
                    _id
                    image {
                        ...ImageWithPreview
                    }
                    name
                    email
                    phone
                    job {
                        en
                        se
                    }
                }
            }
        }
    `);

    return partnersPage;
};

export default usePartnersPage;
