// extracted by mini-css-extract-plugin
export var container = "partnershipPage-module--container--GD0GI";
export var cta__container = "partnershipPage-module--cta__container--fRYPa";
export var cta__header = "partnershipPage-module--cta__header--3hCuH";
export var linkBox__container = "partnershipPage-module--linkBox__container--JN1Kx";
export var linkBox__text = "partnershipPage-module--linkBox__text--gu5rv";
export var partner__container = "partnershipPage-module--partner__container--a--YN";
export var partner__content = "partnershipPage-module--partner__content--JY8-O";
export var partner__header = "partnershipPage-module--partner__header--OtceB";
export var partner__keyPoints = "partnershipPage-module--partner__keyPoints--ru3wl";
export var program__container = "partnershipPage-module--program__container--cg7my";
export var program__form = "partnershipPage-module--program__form--YOdzF";
export var program__formError = "partnershipPage-module--program__formError--A2C8t";
export var program__formSuccess = "partnershipPage-module--program__formSuccess--zUZwZ";
export var program__info = "partnershipPage-module--program__info--t1oMp";
export var program__keypoints = "partnershipPage-module--program__keypoints--PsGR+";