import React, { useContext, useEffect, useState } from 'react';

import * as style from 'styles/components/partners/partnershipPage.module.scss';
import { LangContext } from 'context/LangContext';
import PartnershipForm from 'components/partners/partnershipForm';
import FormattedMessage from 'components/utils/FormattedMessage';
import TextEditorRender from 'components/utils/TextEditorRender';

const PartnershipPrograms = ({ programs, formGdprMessage, formSuccessMessage }) => {
    const { lang } = useContext(LangContext);

    const [emailRegistered, setEmailRegistered] = useState(false);

    const handleSetEmailRegistered = (value) => {
        setEmailRegistered(value);
        localStorage.setItem('partnersFormDone', JSON.stringify(value));
    };

    useEffect(() => {
        const isPartnersFormDone = localStorage.getItem('partnersFormDone');
        if (isPartnersFormDone) {
            handleSetEmailRegistered(JSON.parse(isPartnersFormDone));
        }
    });

    return (
        <section className={`${style.container} wrapper`}>
            <h2 className="title-xs-mobile title-md semibold">
                <FormattedMessage id="partners.partnershipPrograms" />
            </h2>

            {programs
                .filter((program) => program.language.translationKey === lang.hreflang)
                .map((program) => (
                <div key={program._key} className={style.program__container}>
                    <div className={style.program__info}>
                        <h3 className="title-xs semibold">{program.programName}</h3>

                        {program?.programDescription._rawContent && (
                            <TextEditorRender content={program.programDescription._rawContent} />
                        )}
                    </div>

                    <div className={style.program__keypoints}>
                        {program.programKeyPoints.length !== 0 && (
                            <ul>
                                {program.programKeyPoints.map((item, index) => (
                                    <li key={index}>
                                        {item}
                                    </li>
                                ))}
                            </ul>
                        )}
                    </div>

                    <PartnershipForm
                        programName={program.programName}
                        programBrochureUrl={program.programBrochure.asset.url}
                        formGdprMessage={formGdprMessage}
                        formSuccessMessage={formSuccessMessage}
                        emailRegistered={emailRegistered}
                        handleSetEmailRegistered={handleSetEmailRegistered}
                    />
                </div>
            ))}
        </section>
    );
};

export default PartnershipPrograms;
