import React from 'react';

import * as style from 'styles/components/partners/partnershipPage.module.scss';
import StaffList from 'components/misc/StaffList';
import FormattedMessage from 'components/utils/FormattedMessage';

const PartnershipStaff = ({ members }) =>
    <section className={`${style.container} wrapper`}>
        <h2 className="title-xs-mobile title-md semibold">
            <FormattedMessage id="partners.getInTouch" />
        </h2>

        <StaffList members={members} />
    </section>;

export default PartnershipStaff;
