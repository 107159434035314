import React, { useContext, useState } from 'react';

import * as style from 'styles/components/partners/partnershipPage.module.scss';
import { LangContext } from 'context/LangContext';
import FormattedMessage, { getFormattedMessage } from 'components/utils/FormattedMessage';
import TextEditorRender from 'components/utils/TextEditorRender';

const PartnershipForm = ({
    programName,
    programBrochureUrl,
    formGdprMessage,
    formSuccessMessage,
    emailRegistered,
    handleSetEmailRegistered,
}) => {
    const { lang } = useContext(LangContext);
    const richTextKey = `_raw${lang.hreflang.charAt(0).toUpperCase() + lang.hreflang.slice(1)}`;

    const [showForm, setShowForm] = useState(false);
    const [formSubmitted, setFormSubmitted] = useState(false);
    const [formError, setFormError] = useState(false);

    const postUserData = (data) => {
        const requestUrl = process.env.GATSBY_PARTNER_FORM_ENDPOINT;
        const requestParams = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(data),
        };

        fetch(requestUrl, requestParams)
            .then(() => {
                setShowForm(false);
                setFormSubmitted(true);
                handleSetEmailRegistered(true);
                window.open(programBrochureUrl, '_blank');
            })
            .catch((error) => {
                console.warn('Request failed', error);

                setShowForm(false);
                setFormError(true);
                window.open(programBrochureUrl, '_blank');
            });
    };

    const handleFormSubmit = (evt) => {
        evt.preventDefault();

        const formData = new FormData(evt.target);
        const jsonData = {};

        formData.forEach((value, key) => {
            if (key === 'consent') {
                value = value === 'on';
            }

            jsonData[key] = value;
        });

        postUserData(jsonData);
    };

    return (
        <div>
            {/* When the form is submitted display a success message */}
            {formSuccessMessage && formSuccessMessage[richTextKey] && formSubmitted && (
                <div className={style.program__formSuccess}>
                    <TextEditorRender content={formSuccessMessage[richTextKey].content} />
                </div>
            )}

            {/* When the form request failed display an error message */}
            {formError && (
                <div className={style.program__formError}>
                    <FormattedMessage id="partners.formError" />
                </div>
            )}

            {/* Ask for user information */}
            {!showForm && !emailRegistered && (
                <button className="cta-yellow" type="button" onClick={() => setShowForm(true)} aria-label={getFormattedMessage('partners.getInformation', lang)}>
                    <FormattedMessage id="partners.getInformation" />
                </button>
            )}

            {/* Display user form */}
            {showForm && !emailRegistered && (
                <form method="POST" onSubmit={handleFormSubmit} className={style.program__form}>
                    <div className="form-group">
                        <label htmlFor="name"><FormattedMessage id="form.name" /><em>*</em></label>
                        <input type="text" id="name" name="name" autoComplete="name" required
                               placeholder={getFormattedMessage('form.name', lang)} />
                    </div>

                    <div className="form-group">
                        <label htmlFor="email"><FormattedMessage id="form.email" /><em>*</em></label>
                        <input type="email" name="email" id="email" autoComplete="email" required
                               placeholder={getFormattedMessage('form.email', lang)}
                               pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$" />
                    </div>

                    <div className="form-group">
                        {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
                        <label htmlFor="phone"><FormattedMessage id="form.phoneMobile" /></label>
                        <input type="tel" name="phone" id="phone" autoComplete="tel"
                               placeholder={getFormattedMessage('form.phoneMobile', lang)} />
                    </div>

                    <div className="form-group">
                        {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
                        <label htmlFor="company"><FormattedMessage id="form.company" /></label>
                        <input type="text" name="company" id="company" autoComplete="organization"
                               placeholder={getFormattedMessage('form.company', lang)} />
                    </div>

                    <div className="form-group">
                        {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
                        <label htmlFor="role"><FormattedMessage id="form.role" /></label>
                        <input type="text" name="role" id="role" autoComplete="organization-title"
                               placeholder={getFormattedMessage('form.role', lang)} />
                    </div>

                    {formGdprMessage && formGdprMessage[richTextKey] && (
                        <div className="form-group checkbox">
                            <input type="checkbox" name="consent" id="consent" required />
                            <label htmlFor="consent">
                                <TextEditorRender content={formGdprMessage[richTextKey].content} />
                                <em>*</em>
                            </label>
                        </div>
                    )}

                    <input type="hidden" name="programAsked" value={programName} />

                    <p className="form-required">
                        <em>*</em>
                        <FormattedMessage id="form.requiredFields" />
                    </p>
                    <div className="form-submit">
                        <button className="cta-yellow cta-external" type="submit" aria-label={getFormattedMessage('partners.getInformation', lang)}>
                            <FormattedMessage id="partners.getInformation" />
                        </button>
                    </div>
                </form>
            )}

            {/* Display the link if the user already submitted the form */}
            {emailRegistered && (
                <a className="cta-yellow cta-external" href={programBrochureUrl} target="_blank" rel="noreferrer" aria-label={getFormattedMessage('partners.getInformation', lang)}>
                    <FormattedMessage id="partners.getInformation" />
                </a>
            )}
        </div>
    );
};

export default PartnershipForm;
