import React, { } from 'react';

import * as style from 'styles/components/partners/partnershipPage.module.scss';
import TextEditorRender from 'components/utils/TextEditorRender';

const Ingress = ({ ingressContent }) => (
    <section className={`${style.container} wrapper`}>
        <TextEditorRender content={ingressContent} />
    </section>
)

export default Ingress;
